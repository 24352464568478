import {
  Tag,
  TagLabel,
  OrderedList,
  ListItem,
  Link,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { RedFlag } from "__generated__/graphql";
import { InfoOutlineIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { formatDate } from "utils";

interface RedFlagSearchProps {
  results: RedFlag[];
}
export function RedFlagSearch({ results }: RedFlagSearchProps) {
  return (
    <Card w="100%" p="16px" overflow="scroll">
      <CardHeader lineHeight="115%" fontWeight="bold" p="0px 8px 16px 0px">
        <Flex justifyContent="space-between">
          <Text>Results</Text>
          <Flex alignItems="center" gap="12px">
            <Tag borderRadius="full" variant="subtle" colorScheme="cyan">
              <TagLabel pb="3px">Beta</TagLabel>
            </Tag>
            <Tooltip
              backgroundColor="white"
              color="black"
              border="1px solid"
              borderColor="gray.200"
              padding="8px"
              label={
                <Flex direction="column">
                  <Text>
                    {results.filter((r) => r?.query !== null)?.length === 0
                      ? "No query data"
                      : "Showing results for:"}
                  </Text>
                  <OrderedList>
                    {results.map((result, index) => {
                      return result?.query ? (
                        <ListItem pb="14px" fontSize="xs" lineHeight="1rem" key={index}>
                          {result?.query || ""}
                        </ListItem>
                      ) : null;
                    })}
                  </OrderedList>
                </Flex>
              }
              aria-label="A tooltip"
              hasArrow
            >
              <InfoOutlineIcon fontSize="xm" color="indigo.400" />
            </Tooltip>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody p="0">
        <Flex w="100%" justifyContent="space-between" gap="12px">
          <Card
            border="1px solid"
            borderColor="gray.200"
            boxShadow="none"
            w="100%"
            height="max-content"
          >
            <CardBody p="0">
              <Flex direction="column">
                {results.map((result, index) => {
                  return (
                    <Flex
                      direction="column"
                      key={index}
                      backgroundColor={index % 2 === 0 ? "gray.100" : "white"}
                      borderTopRadius={index === 0 ? "3px" : "0"}
                      p="8px"
                    >
                      <Tooltip label={result?.displayLink} aria-label="A tooltip" hasArrow>
                        <Link
                          w="50%"
                          whiteSpace="nowrap"
                          fontSize="xs"
                          href={result.link}
                          isExternal
                          color="indigo.500"
                        >
                          {result?.title}
                          <ExternalLinkIcon mx="2px" />
                        </Link>
                      </Tooltip>
                      <Text fontSize="xs">
                        {`${formatDate(result?.date) || "Date Unknown"} — ${result?.redFlagSummary}`}
                      </Text>
                    </Flex>
                  );
                })}
                {results.length === 0 && (
                  <Flex direction="column" borderTopRadius="3px" padding="8px">
                    <Text fontSize="sm">No Search Results Found</Text>
                  </Flex>
                )}
              </Flex>
            </CardBody>
          </Card>
        </Flex>
      </CardBody>
    </Card>
  );
}
